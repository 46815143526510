import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.3_@babel+core@7.25.8_@playwright+test@1.47.1_react-dom@19.0.0-rc-69d4b800-20241021__gyzi2ufa3xeolzuheawgb3xrx4/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.3_@babel+core@7.25.8_@playwright+test@1.47.1_react-dom@19.0.0-rc-69d4b800-20241021__gyzi2ufa3xeolzuheawgb3xrx4/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/welcome/add-places.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/welcome/categories.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/welcome/italy-map.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/welcome/new-york-map.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/welcome/one-vanderbilt.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/welcome/thailand-map.jpg");
;
import(/* webpackMode: "eager", webpackExports: ["WelcomePageCategorySelect"] */ "/vercel/path0/src/app/[lang]/welcome-page-category-select.tsx");
