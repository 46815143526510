'use client';

import React, { useCallback } from 'react';
import * as Popover from '@radix-ui/react-popover';
import { useIsMobile } from 'src/app/(providers)/DeviceContext';
import { useBoolean } from 'src/hooks/useBoolean';
import { cn } from 'src/utils/cn';
import { Drawer } from 'vaul';

// #region Context
type SelectValue = string[];

type SelectContext = {
  value: SelectValue;
  onOptionSelect: (value: string) => void;
  isPortalOpen: boolean;
  openPortal: () => void;
  closePortal: () => void;
  // internal
  triggerWidth: number;
  setTriggerWidth: (value: number) => void;
};

const SelectContext = React.createContext<SelectContext | null>(null);

const useSelectContext = () => {
  const context = React.useContext(SelectContext);
  if (!context) {
    throw new Error('Select.Root must be used within Select.Root');
  }
  return context;
};
// #endregion

// #region Root
type SelectRootProps = React.PropsWithChildren<{
  value: SelectValue;
  onChange: (value: SelectValue) => void;
}>;

export function Root(props: SelectRootProps) {
  const isMobile = useIsMobile();
  const [isOpen, { setFalse: close, setTrue: open, toggle }] =
    useBoolean(false);
  const [triggerWidth, setTriggerWidth] = React.useState(0);

  const onOptionSelect = (value: string) => {
    if (props.value.includes(value)) {
      return props.onChange(props.value.filter((v) => v !== value));
    }
    props.onChange([...props.value, value]);
  };

  return (
    <SelectContext.Provider
      value={{
        closePortal: close,
        isPortalOpen: isOpen,
        onOptionSelect: onOptionSelect,
        openPortal: open,
        setTriggerWidth,
        triggerWidth,
        value: props.value,
      }}
    >
      {isMobile && (
        <Drawer.Root open={isOpen} onOpenChange={toggle}>
          {props.children}
        </Drawer.Root>
      )}
      {!isMobile && (
        <Popover.Root open={isOpen} onOpenChange={toggle}>
          {props.children}
        </Popover.Root>
      )}
    </SelectContext.Provider>
  );
}
// #endregion

// #region Trigger
type SelectTriggerProps = React.HTMLAttributes<HTMLButtonElement>;

export function Trigger({ children, onClick, ...props }: SelectTriggerProps) {
  const context = useSelectContext();
  const isMobile = useIsMobile();

  const handlePopoverRef = useCallback(
    (node: HTMLButtonElement) => {
      if (!node) {
        return;
      }
      context.setTriggerWidth(node.getBoundingClientRect().width);
    },
    [context.setTriggerWidth]
  );

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (context.isPortalOpen) {
      context.closePortal();
    } else {
      context.openPortal();
    }
    onClick?.(e);
  };

  if (isMobile) {
    return (
      <Drawer.Trigger asChild onClick={handleOnClick} {...props}>
        {children}
      </Drawer.Trigger>
    );
  }

  return (
    <Popover.Trigger
      asChild
      ref={handlePopoverRef}
      onClick={handleOnClick}
      {...props}
    >
      {children}
    </Popover.Trigger>
  );
}
// #endregion

// #region Portal
type SelectPortalProps = React.HTMLAttributes<HTMLDivElement>;

export function Portal({
  children,
  className,
  style,
  ...props
}: SelectPortalProps) {
  const context = useSelectContext();
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <Drawer.Portal>
        <Drawer.Overlay className="fixed inset-0 z-[100] bg-black/40 backdrop-blur-xl" />
        <Drawer.Content className="fixed bottom-0 left-0 right-0 z-[100] flex max-h-[80svh] flex-col rounded-t-[16px] bg-background outline-none">
          <Drawer.Title className="hidden" />
          <Drawer.Description className="hidden" />
          <div className="absolute left-1/2 top-3 z-[1] h-[4px] w-[30vw] -translate-x-1/2 rounded-full border border-transparent bg-secondary/50 sm:hidden" />
          <div
            className={cn(
              'relative mx-auto mt-6 flex h-full w-full flex-col overflow-auto rounded-t-[16px] pb-[max(8px,env(safe-area-inset-bottom))]',
              className
            )}
          >
            {children}
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    );
  }

  return (
    <Popover.Portal>
      <Popover.Content
        className={cn(
          'z-10 max-h-[400px] w-[560px] overflow-auto bg-backgroundLight p-2 shadow-2xl shadow-dark/20 outline-none',
          className
        )}
        sideOffset={6}
        align="start"
        style={{
          ...style,
          width: context.triggerWidth,
        }}
        {...props}
      >
        {children}
      </Popover.Content>
    </Popover.Portal>
  );
}
// #endregion

// #region Option
type SelectOptionProps = React.HTMLAttributes<HTMLDivElement> & {
  value: string;
};

export function Option({ children, className, ...props }: SelectOptionProps) {
  const context = useSelectContext();

  const handleOnClick = () => {
    context.onOptionSelect(props.value);
  };

  return (
    <div
      className={cn('cursor-pointer hover:bg-background', className)}
      onClick={handleOnClick}
      {...props}
    >
      {children}
    </div>
  );
}
// #endregion
